import React, { useCallback, useMemo, useState } from 'react';
import CustomModal from "./lib/CustomModal";
import Axios from 'axios';
import { Checkbox, Table, TextInput } from "react-materialize";

interface Product {
  top_img_url: string
}

interface Order {
  id: number
  price: number
  product: Product
  product_name: string
  product_price_min: number
  product_price_max: number
  product_discount_amount: number
}

interface Rescue {
  id: number
  orders: Array<Order>
}

type OrderDetailRecordType = {
  order: Order
  price: number
  handleChangePrice: (orderId: number, price: number) => void
  handleChangeCheckbox: (orderId: number) => void
}


const discountPrice = (price: number, discountAmount: number) => {
  const discountPrice = price - discountAmount;

  if (discountPrice < 0) return 0;

  return discountPrice;
};

const OrderDetailRecord: React.FC<OrderDetailRecordType> = ({ order, price, handleChangePrice, handleChangeCheckbox }) => {
  const [isChecked, setIsChecked] = useState(true);

  const onChangeCheckbox = () => {
    handleChangeCheckbox(order.id);
    setIsChecked(!isChecked);
  };

  return (
    <tr style={{ borderBottom: 'none' }}>
      <td>
        <span className="valign-wrapper">
          <img
            src={order.product.top_img_url}
            width={40}
            height={40}
          />
          <span className="ml-4">
            {order.product_name}
          </span>
        </span>
      </td>
      <td>{order.product_price_min} 〜 {order.product_price_max} 円</td>
      <td>{order.product_discount_amount} 円</td>
      <td>
        <Checkbox label="" value="" onChange={(e) => onChangeCheckbox()} checked={isChecked} />
      </td>
      <td className='valign-wrapper'>
        <TextInput
          name={'price'}
          l={4}
          type={'number'}
          disabled={!isChecked}
          value={price.toString()}
          defaultValue={price.toString()}
          onChange={(e) => handleChangePrice(order.id, Number(e.target.value))}
          s={12}
        />円
      </td>
      <td>
        {isChecked && discountPrice(price, order.product_discount_amount) } 円
      </td>
    </tr>
  );
};

interface Props {
  rescue: Rescue
  rejectAllPath: string
  approvePath: string
}

const ApproveRescueModal: React.FC<Props> = ({
  rescue,
  rejectAllPath,
  approvePath,
}) => {
  const initialApprovedPriceOrderMap = () => {
    const map = new Map<number, number>();
    rescue.orders.forEach(order => map.set(order.id, order.price));
    return map;
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [approvedOrderIds, setApprovedOrderIds] = useState<Set<number>>(new Set(rescue.orders.map(order => order.id )));
  const [approvedPriceOrderMap, setApprovedPriceOrderMap] = useState(initialApprovedPriceOrderMap);

  const handleClickApprove = useCallback(async () => {
    const orders = Array.from(approvedPriceOrderMap.entries()).map(([orderId, price]) => {
      if(approvedOrderIds.has(orderId)) return { id: orderId, price: price };
    }).filter(v => v);

    // 何も承認していないときは却下にする
    if(orders.length === 0) return await Axios.post(rejectAllPath, { cash_id: rescue.id});

    await Axios.post(approvePath, { orders });
    setShowModal(false);
  }, [approvedOrderIds, approvedPriceOrderMap]);

  const handleClickCancel = useCallback(() => setShowModal(false), []);

  const handleClickRejectAll = useCallback(async () => {
    await Axios.post(rejectAllPath, { cash_id: rescue.id});
  }, []);

  const handleChangePrice = useCallback((orderId, newPrice) => {
    setApprovedPriceOrderMap(prev => {
      prev.set(orderId, newPrice);
      return new Map(prev);
    });
  }, []);

  const handleChangeCheckbox = useCallback((orderId: number) => {
    setApprovedOrderIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);

        setApprovedPriceOrderMap(prev => {
          prev.set(orderId, 0);
          return new Map(prev);
        });
      } else {
        newSet.add(orderId);
      }

      return newSet;
    });
  }, []);

  const totalPrice = () => useMemo(() => Math.floor(Array.from(approvedPriceOrderMap.entries()).map(([orderId, price]) => discountPrice(price, rescue.orders.find((order) => order.id === orderId ).product_discount_amount)).reduce((prev, current) => prev + current, 0) * 1.08), [approvedPriceOrderMap]);

  return (
    <>
      <button
        type="button"
        className='tabete-color btn bold'
        onClick={() => setShowModal(true)}
      >承認・却下</button>

      <CustomModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        ariaHideApp={false}
      >
        <div className="row mt-3">
          <h5 className='center-align'>注文を承認または却下する</h5>

          <div className="mt-4 text-xl">
            <Table className="approve-orders" centered={true}>
              <thead>
                <tr style={{ borderBottom: 'none' }}>
                  <th>商品名</th>
                  <th>元値</th>
                  <th>割引額</th>
                  <th>承認する</th>
                  <th>商品単価(税抜)</th>
                  <th>割引後価格(税抜)</th>
                </tr>
              </thead>

              <tbody>
                {rescue.orders.map(order => (
                  <OrderDetailRecord
                    order={order}
                    price={approvedPriceOrderMap.get(order.id)}
                    handleChangePrice={handleChangePrice}
                    handleChangeCheckbox={handleChangeCheckbox}
                    key={order.id}
                  />
                ))}
              </tbody>
            </Table>
            <div className='right-align' style={{marginBottom: "20px"}}>合計金額(税込) { totalPrice() }円</div>
          </div>

          <div className="center text-xl">
            <button
              type="button"
              className="btn btn-large waves-effect waves-light btn-block waves-input-wrapper"
              style={{ backgroundColor: "whitesmoke", color: "black" }}
              onClick={handleClickCancel}
            >キャンセル</button>
            <button
              type="button"
              className='btn-large bold button-approve'
              style={{
                backgroundColor: '#d9534f'
              }}
              onClick={handleClickRejectAll}
            >全て却下</button>
            <button
              type="button"
              className='btn-large bold tabete-color'
              onClick={handleClickApprove}
            >選択した個数で承認</button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ApproveRescueModal;
